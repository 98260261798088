.mainContainer {
  display: flex;
}

/* Sidebar */
.sidebar {
  overflow: auto;
  position: fixed;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  top: 0;
  bottom: 0;
  max-height: 47rem !important;

  &::-webkit-scrollbar {
    display: none;
  }

  .bars {
    float: right;
    padding-right: 1rem;
    width: 30px;
    cursor: pointer;
    margin-top: -0.8rem;

    svg {
      color: $white;
      font-size: 1.5rem;

      &:hover {
        color: $orange;
        transition: 0.4s ease-in-out;
      }
    }
  }

  .top-section {
    justify-content: space-between;
    padding: 15px 10px;
  }

  .sidebarMenu {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    ul {
      list-style: none;

      .link {
        display: flex;
        color: #6c757d;
        gap: 10px;
        padding: 5px 10px;
        cursor: pointer;

        // box-shadow: 0 0 0 #202125, -0 -0 0 #353535;

        &:hover {
          box-shadow: inset 3px 3px 3px #202125, inset -3px -3px 3px #353535;
        }

        &.active {
          box-shadow: inset 3px 3px 3px #202125, inset -3px -3px 3px #353535;
          color: $orange !important;
        }

        .icon {
          width: 2rem;
          height: 2rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .text {
          margin-top: 0.3rem !important;
        }
      }
    }
  }
}

// Actual style of profile-image
.profile-image {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  padding: 0.5625rem;
  background-color: #2c2d30;
  width: 11.25rem;
  height: 11.25rem;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 3px 3px 3px #202125, inset -3px -3px 3px #353535;
    border-radius: 50%;
    z-index: 0;
    left: 0;
  }

  .profile-image-border {
    box-shadow: 3px 3px 3px #202125, -3px -3px 3px #353535;
    border-radius: 50%;
    padding: 0.375rem;
    position: relative;
    z-index: 2;

    img {
      width: 9.375rem;
      height: 9.375rem;
      display: inline-block;
    }
  }
}

@media screen and (max-width: 767px) {
  .mobile-d-none,
  .sidebar {
    display: none !important;
  }

  main {
    padding: 0 !important;
    z-index: 9999;
  }

  .mainContainer {
    position: sticky;
  }

  .mobile {
    .profile-image {
      width: 14rem;
      height: 14rem;
      vertical-align: middle;
      padding-top: 0.7rem;

      .profile-image-border {
        img {
          width: 12rem;
          height: 12rem;
        }
      }
    }
  }
  .mobileMenu {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    z-index: 2;

    ul {
      display: flex;
      list-style: none;
      padding: 0 1rem;
      gap: 0.5rem;
      margin: 0;

      li {
        padding: 0.5rem 0;

        .link {
          display: flex;
          padding: 5px 10px;
          cursor: pointer;

          &:hover,
          &.active {
            box-shadow: inset 3px 3px 3px #202125, inset -3px -3px 3px #353535;
          }

          .icon {
            width: 2rem;

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .mobile,
  .mobileMenu {
    display: none !important;
  }
}

@media screen and (min-width: 1023px) {
  .mobile,
  .mobileMenu {
    display: none !important;
  }
}
