.LoaderContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  touch-action: none;
  overflow: hidden;

  width: 100vw;
  height: 100vh;

  z-index: 6;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: $dark;
  color: $white;

  * {
    text-align: center;
  }

  .name {
    font-size: $font-lg;
    font-weight: 700;
  }

  .greeting {
    color: $orange;
    font-size: $font-med;
    padding-top: 1rem;
    margin-bottom: 0 !important;
  }

  .text {
    font-size: $font-med;
    color: $white;

    @media (max-width: 48em) {
      font-size: $font-lg;
    }
  }
}
