#home {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;

  ul {
    li {
      margin-right: 1rem !important;
    }
  }
}

.v-centred {
  vertical-align: middle;
  align-items: center;
  display: flex;
  display: -ms-flexbox;
  -ms-flex-pack: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.btn-round {
  font-size: 0.75rem;
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
  padding: 0;
}

.btn.btn-round:hover {
  color: $orange;
}

.item {
  position: relative;
  overflow: hidden;

  h6 {
    color: $orange;
  }
}

.btn-icon {
  color: $white;

  &:hover {
    border-radius: 50% !important;
    box-shadow: inset 3px 3px 3px #202125, inset -3px -3px 3px #353535 !important;
  }
}

.btn-icon-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 1rem;
}

.content {
  width: 100%;
}
