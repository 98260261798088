#contact {
  .ui-icon-lg img {
    width: 2.5rem;
  }

  .outer button {
    color: $orange;
    background: transparent;
    outline: none;
    border: none;

    &:hover {
      box-shadow: inset 3px 3px 3px #202125, inset -3px -3px 3px #353535;
      transition: 0.3s ease;
    }
  }

  iframe {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    border: none;
  }
}

// Info

.info-before::after,
.info-before:before {
  content: none;
}

@media (min-width: 992px) {
  .info-before:before {
    content: '';
    position: absolute;
    top: 2.875rem;
    right: 0;
    bottom: 0;
    box-shadow: inset 3px 3px 3px #202125, inset -3px -3px 3px #353535;
    border-radius: 50%;
    z-index: 0;
    left: 50%;
    width: 96%;
    transform: translateX(-50%);
    height: 65%;
    border-radius: 1rem;
    height: 13rem;
  }
  .info-before::after {
    content: '';
    position: absolute;
    top: 3.4375rem;
    right: 0;
    bottom: 0;
    box-shadow: 3px 3px 3px #202125, -3px -3px 3px #353535;
    border-radius: 50%;
    z-index: 0;
    left: 50%;
    width: 94%;
    transform: translateX(-50%);
    height: 59%;
    border-radius: 1rem;
    z-index: -1;
    height: 11.76rem;
  }
}

// Email

.resize-n {
  resize: none;
}

.form-control {
  display: block;
  width: 100%;
  padding: 10px 24px;
  font-size: 0.875rem;
  line-height: 1.7;
  color: #9da1a5;
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  background-clip: padding-box;
  border: 1px solid #2c2d30;
  border-radius: 2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus,
  :hover {
    border: 1px solid #2c2d30;
    box-shadow: inset 3px 3px 3px #202125, inset -3px -3px 3px #353535;
    color: #9da1a5;
  }
}
