#blogs {
  .media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;

    .media-body {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      display: block !important;
    }
  }

  span {
    a {
      padding: 0 !important;
      box-shadow: none;
    }
  }

  .outer a {
    &:hover {
      box-shadow: inset 3px 3px 3px #202125, inset -3px -3px 3px #353535;
      transition: 0.3s ease;
    }
  }
}
