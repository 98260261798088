.BlogContent {
  .BlogTitle {
    color: $orange;
    font-weight: 500;
    font-size: 2.125rem;
  }

  .frontEndBox {
    .imageContainer {
      height: 12rem;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    h5 {
      font-size: $font-med;
      font-weight: bold;
      margin-bottom: 0.7rem;
      span {
        color: $orange;
      }
    }
  }
}
