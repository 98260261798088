.header-search-form input[type='text'] {
  background: transparent;
  color: #ffffff;
  font-size: 0.8125rem;
  border: none;
  width: 100%;
  text-transform: uppercase;
  padding: 0.6875rem 1.5rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.8);
  outline: none;
}
.header-search-form input[type='text']:focus {
  outline: none;
}
.header-search-form input[type='submit'] {
  height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  width: 0;
  display: none;
}
.header-search-form .search-form-icon img {
  width: 2rem;
  position: absolute;
  right: 1.5625rem;
  top: 0.325rem;
  cursor: pointer;
}
