@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

// Colors
$dark: #2c2d30;
$darkBlue: #184586;
$orange: #e57525;
$white: hsl(0, 7%, 92%);

// Font Sizes
$font-sm: 0.875rem; // 14px
$font-med: 1.125rem; // 18px
$font-lg: 1.25rem;
$font-xlg: 3.25rem;
$font-family: 'Montserrat', sans-serif;

.font-xsm {
  font-size: 0.765rem;
}

.font-sm {
  font-size: 0.889rem;
}

.font-med {
  font-size: 1rem;
}

.font-lg {
  font-size: 1.125rem;
}

.font-xlg {
  font-size: 1.25rem;
}

.font-weight-xxs {
  font-weight: 200;
}
.font-weight-xs {
  font-weight: 300;
}
.font-weight-sm {
  font-weight: 400;
}
.font-weight-md {
  font-weight: 500;
}
.font-weight-xmd {
  font-weight: 600;
}
.font-weight-lg {
  font-weight: 700;
}
