// PersonalInfo

ul.info-list li,
ul.info-list li .inf,
ul.info-list li .value {
  width: auto;
  border: none;
  width: 100%;
}
ul.info-list li:last-child {
  border-bottom: 0;
}
ul.info-list li .inf {
  position: relative;
  color: #fafafa;
  font-weight: 600;
}
ul.info-list li .inf,
ul.info-list li .value {
  display: inline-block;
  padding: 0.5rem 0;
}

@media (min-width: 992px) {
  .vc {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: row;
    -webkit-flex-direction: row;
  }
  ul.info-list li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  }
  ul.info-list li .inf {
    width: 28%;
    border-right: 1px solid rgba(255, 255, 255, 0.07);
  }
  ul.info-list li .value {
    width: 70%;
  }
  ul.info-list li:last-child {
    border: none;
  }
  .timeline-content {
    padding-left: 4rem;
  }
}

// skills

.title {
  padding: 0.3125rem 1.5625rem;
  transform: rotate(0deg);
  -webkit-transform: rotate(0);
  margin-top: 1rem;
  border-radius: 1rem;
  text-align: center;
  text-transform: uppercase;
  box-shadow: 3px 3px 3px #202125, -3px -3px 3px #353535;
  border-radius: 1rem;
  background-color: #2c2d30;
}

@media (min-width: 992px) {
  .title {
    transform: rotate(90deg);
    left: -15px;
    top: -0.9375rem;
    width: fit-content;
  }
}

.progress {
  display: block;
  height: 1rem;
  position: relative;
  width: 100%;
  overflow: visible;
  border-radius: 3rem;
  background: #2c2d30;
  box-shadow: inset 3px 3px 3px #202125, inset -3px -3px 3px #353535;

  .percentage {
    border-radius: 2em;
    left: 0.4375rem;
    position: absolute;
    top: 50%;
    height: 0.375rem;
    transform: translateY(-50%);
  }
}

.skill.des .progress .percentage {
  background-color: rgba(255, 255, 255, 0.8);
}

.skill.prs .progress .percentage,
.skill.dev .progress .percentage {
  background-color: $orange;
}

.title.des {
  color: #fafafa;
}
.title.dev {
  color: $orange;
}
.title.prs {
  color: #9da1a5;
}

// .vc {
//   display: inline;
// }

// Education

.timeline {
  position: relative;

  &:before {
    background: #2c2d30;
    box-shadow: inset 3px 3px 3px #202125, inset -3px -3px 3px #353535;
    bottom: 0;
    content: '';
    left: 1.375rem;
    position: absolute;
    top: 0.0625rem;
    width: 0.5rem;
    height: 100%;
    border-radius: 3em;
  }

  img {
    width: 2.5rem !important;
  }
}

.ui-icon-md img {
  width: 1.3rem;
}
.ui-icon-lg img {
  width: 1.8rem;
}
.ui-icon-xl img {
  width: 2rem;
}
.ui-icon-lg-2 img {
  width: 2.5rem;
}
.ui-icon-xxl img {
  width: 3rem;
}
.ui-icon-outer-lg {
  width: 4rem;
}
.ui-icon-md {
  width: 3.3rem;
  height: 3.3rem;
}
.ui-icon-lg {
  width: 4rem;
  height: 4rem;
}
.ui-icon-xl {
  width: 6rem;
  height: 6rem;
}
.ui-icon {
  position: relative;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  line-height: 0.7;
  background-color: #2c2d30;

  &:hover {
    box-shadow: inset 3px 3px 3px #202125, inset -3px -3px 3px #353535 !important;
  }
}
.ui-icon-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ui-icon-inner-lg img {
  width: 4rem;
  height: 4rem;
}

.timeline-content {
  padding-left: 3.375rem;
  padding-top: 1.25rem;
  position: relative;
  margin-bottom: 0.625rem;

  &:after {
    background: $orange;
    content: '';
    height: 0.625rem;
    left: 1.5625rem;
    position: absolute;
    text-align: center;
    top: 1.5rem;
    width: 0.25rem;
    border-radius: 2em;
  }

  span.current {
    color: $orange;
    background: #2c2d30;
    border-radius: 2em;
    padding: 0.125rem 0.75rem;
    box-shadow: inset 3px 3px 3px #202125, inset -3px -3px 3px #353535;
    font-size: 0.75rem;
    font-weight: 500;
  }
}

// Services

.service {
  cursor: default;
}

.services-box:hover,
:focus {
  box-shadow: inset 3px 3px 3px #202125, inset -3px -3px 3px #353535;
  padding: 1rem;
  border-radius: 1.5rem;
  transition: 0.5s all ease-in-out !important;
  transform: scale(1.02) !important;

  &::after {
    box-shadow: 3px 3px 3px #202125, -3px -3px 3px #353535 !important;
  }
}

// TESTIMONIALS
.testimonial-image {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  padding: 0.625rem;
  margin-top: -2.375rem;
  background-color: #212529;
  width: 7.1857rem;
  height: 7.1857rem;

  img {
    width: 5.3125rem;
    height: 5.3125rem;
    display: inline-block;
    border-radius: 50%;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 3px 3px 3px #202125, inset -3px -3px 3px #353535;
    border-radius: 50%;
    z-index: 0;
    left: 2px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0.125rem;
    width: 92%;
    height: 40%;
    background-color: #212529 !important;
    z-index: 1;
    right: 6px;
  }

  .testimonial-image-border {
    box-shadow: 3px 3px 3px #202125, -3px -3px 3px #353535;
    border-radius: 50%;
    padding: 0.3125rem;
    position: relative;
    z-index: 2;
  }
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  height: 0.4rem;
  width: 1rem;
  border-radius: 0.3rem;
  background-color: $white;
  transition: 0.4s ease-in-out;
  box-shadow: 3px 3px 3px #202125, -3px -3px 3px #353535 !important;
}

.swiper-pagination-clickable .swiper-pagination-bullet-active {
  background: $orange;
  height: 0.4rem;
  width: 1.5rem;
  border-radius: 0.3rem;
  transition: 0.4s ease-in-out;
}
