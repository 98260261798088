* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: $font-family;
  outline: none !important;
}

html::-webkit-scrollbar {
  width: 0px; /* For Chrome, Safari, and Opera */
}

html:focus-within {
  scroll-behavior: smooth;
}

html {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  scroll-behavior: smooth;
}

body {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.8;
  background-color: $dark;
  color: $white;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: $white;
  transition: 0.5s;
  &:hover,
  &:active,
  &:focus {
    color: $white;
    outline: none;
    text-decoration: none !important;
  }
}
p {
  margin-bottom: 1.5em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  //color: $brand-black;
  //font-family: $font-secondary;
  font-weight: 400;
  margin: 0 0 30px 0;
}

.shadow {
  box-shadow: 3px 3px 3px #202125, -3px -3px 3px #353535 !important;
}

.radius-2 {
  border-radius: 2rem;
}

.active.inner {
  box-shadow: inset 3px 3px 3px #202125, inset -3px -3px 3px #353535;
}

.active.outer {
  box-shadow: 0 0 0 #202125, -0 -0 0 #353535;
}

.outer:hover,
a:hover .outer,
.timeline:hover .outer,
.service:hover .outer {
  box-shadow: 0 0 0 #202125, -0 -0 0 #353535;
}

.outer,
a .outer {
  box-shadow: 3px 3px 3px #202125, -3px -3px 3px #353535;
}

.inner,
.outer,
a .inner,
a .outer {
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  border: 0;
  opacity: 1 !important;
}

hr.divider {
  position: relative;
  box-shadow: inset 1.5px 1.5px 1.5px #202125, inset -1.5px -1.5px 1.5px #353535;
  height: 0.7rem !important;
  border-radius: 1em;
  border: 0;
}

hr.divider-lg {
  width: 3.75rem;
}

hr.divider-md {
  width: 2.5rem;
}

hr.divider-center:after {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

hr.divider-left:after {
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}

hr.divider:after {
  content: '';
  position: absolute;
  height: 0.3rem;
  background: $orange;
  width: 50%;
  border-radius: 3em;
}

.radius-1 {
  border-radius: 1rem;
}
.radius-3 {
  border-radius: 3rem;
}
.radius-2 {
  border-radius: 2rem;
}
.radius-5 {
  border-radius: 5rem;
}

.shadow-inner {
  box-shadow: inset 3px 3px 3px #202125, inset -3px -3px 3px #353535;
}

// figure {
//   margin-bottom: 2.5em;
// }

section,
.section {
  position: relative;
  width: 100%;
  z-index: 4;
  overflow: hidden;
}

.st0,
.st1,
.st2,
.st3,
.st4,
.st5,
.st6,
.st7,
.st8,
.st9 {
  stroke: $orange;
}
svg.svg-icon {
  fill: $orange;
  stroke: $orange;
}
hr.divider:after,
.timeline-content:after {
  background-color: $orange;
}
mark {
  background-image: linear-gradient(120deg, $orange 0, $orange 100%);
}
.skill.dev .progress .percentage,
.bounce-circle,
::selection {
  background-color: $orange;
}
.timeline-content span.current,
.title.dev,
.filter li a.active,
a,
.text-secondary {
  color: $orange;
}
h1 > a:hover,
h2 > a:hover,
h3 > a:hover,
h4 > a:hover,
h5 > a:hover,
h6 > a:hover,
.h1 > a:hover,
.h2 > a:hover,
.h3 > a:hover,
.h4 > a:hover,
.h5 > a:hover,
.h6 > a:hover,
a:hover,
.btn.btn-round:hover {
  color: $orange;
}

a,
::before {
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}

@media (max-width: 575px) {
  h1,
  .h1 {
    font-size: 2rem;
  }
  h2,
  .h2 {
    font-size: 1.875rem;
  }
  h5,
  .h5 {
    font-size: 1.15rem;
  }
  .p-m-0-sm {
    margin: 0 !important;
    padding: 0 !important;
  }
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  height: 5px !important;
  box-shadow: inset 3px 3px 3px #202125, inset -3px -3px 3px #3e3e3e;
  border-radius: 1em;
}

.orange-text-color {
  color: $orange !important;
}

// media
.media {
  // display: -webkit-box;
  // display: -ms-flexbox;
  // display: flex;
  // -webkit-box-align: start;
  // -ms-flex-align: start;
  // align-items: flex-start;
  margin: 0 auto;
}

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: flex;
}

main {
  overflow: hidden;
}

#tsparticles {
  position: sticky;
}

.active.outer {
  box-shadow: 0 0 0 #202125, -0 -0 0 #353535;
}

.active.inner {
  box-shadow: inset 3px 3px 3px #202125, inset -3px -3px 3px #353535;
}
