.BlogContent {
  span {
    a {
      padding: 0 !important;
      box-shadow: none;
    }
  }

  a {
    box-shadow: none;
  }

  .outer a {
    &:hover {
      box-shadow: inset 3px 3px 3px #202125, inset -3px -3px 3px #353535;
      transition: 0.3s ease;
    }
  }
}

.comments-list li:not(:last-child) .comment {
  margin-bottom: 1.5rem;
  overflow: hidden;
}
.avatar-sm {
  width: 3rem;
  height: 3rem;
}
.comment + .comment:before {
  background: #2c2d30;
  box-shadow: inset 3px 3px 3px #202125, inset -3px -3px 3px #353535;
  bottom: 0;
  content: '';
  left: 20px;
  position: absolute;
  top: 0.0625rem;
  width: 0.5rem;
  height: 100%;
  border-radius: 3em;
}
.comment + .comment {
  padding-left: 3rem;
  margin-left: 5%;
  position: relative;
}

.comment {
  .media {
    display: flex;
    -webkit-box-align: start;
  }

  .media-body {
    flex-direction: column;
    margin-right: 1rem;
  }
}
