.filter li {
  display: inline-block;
  font-size: 0.8125rem;
  text-transform: uppercase;
  font-weight: 500;

  &:last-child {
    margin-right: 0;
  }

  span {
    padding: 0.5rem 1.125rem;
    display: block;
    color: #9da1a5;
    cursor: pointer;

    &.active {
      color: $orange;
    }
  }
}

.item {
  img {
    height: auto;
    width: 100%;
    padding: 0.3125rem;
  }
}
.hover-effect-container {
  position: absolute;
  top: -1.25rem;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: 0.4s;
}

.hover-effect-icon {
  display: inline-block;
  text-align: center;
  color: #fff;
  font-size: 0.75rem;
}
.hover-effect:hover .hover-effect-container {
  top: 50%;
}

.hover-effect:hover:before {
  background-color: rgba(44, 45, 48, 0.7);
}

.hover-effect {
  position: relative;
  display: block;
  overflow: hidden;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(44, 45, 48, 0);
  }
}

/* modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 27, 0.541);
  display: flex;
  align-items: center;

  img {
    display: block;
    max-width: 60%;
    max-height: 80%;
    margin: 60px auto;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  }

  & > span {
    position: absolute;
    top: 20px;
    right: 20px;
    color: $orange;
    z-index: 999;
    cursor: pointer;
    font-size: 1.2rem;
    transition: all 1s ease;
    font-weight: 700;

    &:hover {
      color: $white;
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
